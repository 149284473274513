<template>
  <div class='color-f'>
    <!-- 顶部 -->
    <Top :shouback="true" />
    <div class=" pt42 pl30 pr30">
      <!-- 选项卡 -->
      <div class="flex ">
        <div :class="['menu_box_item mr17 pl20 pr20',item.value==query.dataType?'active':'']" v-for="item in tabList"
          :key="item" @click="menuChange(item)">{{item.title}}</div>
      </div>
      <!-- 表格区域 -->
      <div class="table_contant">
        <!-- 搜索区域 -->
        <div class="flex  col-center">
          <div class="w334">
            <el-input v-model="query.keyWord" placeholder="请输入设备编号或设备名称" class="color" />
          </div>
          <div class="search_box ml15" @click="search">搜索</div>
          <div class="search_box ml15" @click="reset">重置搜索</div>
        </div>
        <!-- //搜索区域 -->
        <!-- //防疫机器人表格 -->
        <FangyiRobot :list='tableList' @updateList='getList' :loading='loading' :listType='query.dataType'
          @editName='editName' v-if="query.dataType=='3'" />
        <!-- //防疫机器人表格 -->
        <!--等离子水离子表格 -->
        <SterilizeTable :list='tableList' :loading='loading' @editName='editName' @updateList='getList'
          @openFixedTime='openFixedTime' :listType='query.dataType' :selectDeviceId='selectDeviceId'
          @checkTable='checkTable' v-else />
        <!-- //等离子水离子表格 -->
        <!-- 分页 -->
        <div class=" flex row-center mt25 col-center">
          <el-pagination layout="prev, pager, next" :total="total" :current-page='query.pageNum'
            :page-size='query.pageSize' @current-change='changePage' />
          <div class="export_box mr20 ml20" @click="exportExlce(1)" v-show="query.dataType!='3'">
            <i class="el-icon-position"></i>
            导出选中数据
          </div>
          <div class="export_box" @click="exportExlce(2)" v-show="query.dataType!='3'">
            <i class="el-icon-position"></i>
            导出全部数据
          </div>
        </div>
      </div>
    </div>
    <!-- 修改名称弹层 -->
    <div class="edit_name">
      <el-dialog v-model="dialogVisible" title="修改设备名称" width="30%" :before-close="handleClose">
        <div class="fs16 mb10">设备名称:</div>
        <el-input v-model="eidtNameObj.newName" placeholder="请输入新设备名称" />
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="editNameOk">
              确认
            </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <!-- //修改名称弹层 -->
    <!-- 导出选择时间弹层 -->
    <div class="select_time">
      <SelectTime :exportDiglog='exportDiglog' @confirmExport='confirmExport' v-if="exportDiglog"
        @closeExportDiglog='closeExportDiglog' />
    </div>
    <div class="fixed_time">
      <FixedTime :fixedDialog='fixedDialog' :deviceId='deviceId' @closeFixedTime='closeFixedTime' />
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from 'vue';
import Top from "../../components/top.vue";
import SterilizeTable from './components/sterilizeTable.vue'  //等水离子列表
import FangyiRobot from './components/fangyiRobot.vue'  //防疫机器人列表
import SelectTime from './components/selectTime.vue'  //选择时间弹层
import FixedTime from './components/fixedTime.vue'  //选择时间弹层
import { pickDate } from '@/utils/pubilcjs.js'
import { formatDate } from '@/filters'
import { formatSeconds } from '@/utils/pubilcjs'
//获取列表api
import { deviceList, modifyDeviceName, getDegnlizihis, getshuilizihis, plasmalonData,plasmaIonData } from '@/api/list'
import Message from 'element-plus/lib/el-message';
import { ElLoading, ElMessageBox } from 'element-plus'
import $lodash from "lodash"
const SelectTimeDom = ref()
// 节流阀
let loading = ref(false)
//总数
let total = ref(0)
let input = ref('')
const clientId = sessionStorage.getItem('clientId')
//tabl栏
let lookType = (JSON.parse(sessionStorage.getItem('lookType'))).sort((a, b) => a - b)  //从小到大排序
const tabList = computed(() => {
  let arr = []
  lookType.filter(item => item != 6).forEach(item => {
    let obj = {}
    if (item == 0) {
      obj.value = item
      obj.title = 'B-MZC01 等离子体空气消毒机'
    } else if (item == 1) {
      obj.value = item
      obj.title = 'Y-MZH20 水离子空气消毒机'
    } else if (item == 2) {
      obj.value = item
      obj.title = '防疫通数字哨兵'
    } else if (item == 3) {
      obj.value = item
      obj.title = '防疫机器人'
    }
    arr.push(obj)
  })
  arr
  return arr
})
//请求参数
const query = reactive({
  pageNum: 1,
  pageSize: 10,
  dataType: lookType[0],
  keyWord: ''
})
//列表
let tableList = ref([])
//获取列表
const getList = () => {
  if (!loading.value) {
    loading.value = true
    deviceList(query).then(res => {
      tableList.value = res.data.obj
      total.value = res.data.count
    }).finally(() => {
      loading.value = false
    })
  }
}
getList()
//点击tab栏切换
const menuChange = (item) => {
  if (!loading.value) {
    query.dataType = item.value
    getList()
  } else {
    Message.warning('点太快啦...')
  }
}
//页码值切换
const changePage = (e) => {
  query.pageNum = e
  getList()
}

//修改名称弹层
let dialogVisible = ref(false)
let eidtNameObj = reactive({
  newName: '',
  userDeviceId: ''
})
const editName = (row) => {
  let obj = $lodash.cloneDeep(row);
  eidtNameObj.newName = obj.name || obj.deviceName
  eidtNameObj.userDeviceId = obj.id
  dialogVisible.value = true
}
//确认修改
const editNameOk = () => {
  modifyDeviceName(eidtNameObj).then(res => {
    Message.success('修改成功')
    eidtNameObj.name = ''
    eidtNameObj.userDeviceId = ''
    getList()
    dialogVisible.value = false
  })
}
//点击搜索
const search = () => {
  query.pageNum = 1
  getList()
}
//重置搜索
const reset = () => {
  ElMessageBox.confirm('确认重置么?').then(() => {
    query.pageNum = 1
    query.keyWord = ''
    getList()
  })
}
//表格多选回调
let selectDeviceId = ref([])
const checkTable = (arr) => {
  selectDeviceId.value = arr
}

//导出弹层
let exportDiglog = ref(false)
//导出选中或者全部导出
const exportExlce = (val) => {
  // 1是导出选中  2是导出全部
  if (val == 1) {
    if (selectDeviceId.value.length <= 0) {
      Message.warning('未选中任何数据')
    } else {
      exportDiglog.value = true
    }
  } else {
    selectDeviceId.value = []
    exportDiglog.value = true
  }
}
//确定导出的回调
const confirmExport = (time) => {
  if (time) {
    //导出的时间
    let exprotQuery = {
      startTime: pickDate(time[0]) + 'T00:00:00',
      endTime: pickDate(time[1]) + 'T23:59:59',
      deviceId: selectDeviceId.value
    }
    //提示的配置项
    const options = {
      text: '正在导出中',
    }
    let loadingInstance = ElLoading.service(options)  //打开loading
    let headers = {}  //表头
    let api = null
    let filename = null
    if (query.dataType == 0) { //导出等离子
      headers = {
        '日期': 'exportDate',
        '设备名称': 'deviceName',
        '设备编号': 'deviceSn',
        '开机时间': 'startTime',
        '关机时间': 'finishTime',
        '当日消毒时长': 'workingTime',
        '异常时长': 'exceptionTime',
      }
      filename = '等离子体消毒机'
      if (clientId == '116') {
        api = plasmaIonData(exprotQuery)
      } else {
        api = getDegnlizihis(exprotQuery)
      }

    } else if (query.dataType == 1) {
      headers = {
        '设备编号': 'deviceId',
        '设备名称': 'name',
        '设备类型': 'dataType',
        '日期': 'value',
        '当日保护时长(h)': 'workingTime',
        '保护率': 'workNumber',
        '开启时间': 'startTime',
        '关闭时间': 'finishTime',
      }
      filename = '水离子体消毒机'
      api = getshuilizihis(exprotQuery)
    }
    import('@/vendor/Export2Excel').then(async excel => {
      try {
        const { data } = await api
        let list = []
        if (query.dataType == '0') {
          list = data.result
        } else if (query.dataType == '1') {
          list = data
        }
        if (list.length > 0) {
          const data1 = formatJson(headers, list)
          let companyName = sessionStorage.getItem('a_title')
          const multiHeader = [[`${companyName}`, '', '', '', '', '', ''],
          [`开始日期:${pickDate(time[0])}  结束日期:${pickDate(time[1])}`, '', '', '', '', '', ''],
          [`设备总数:${data.deviceAll}  运行总时长:${formatSeconds(data.allTime / 1000)}`, '', '', '', '', '', '']]
          const merges = ['A1:G1', 'A2:G2', 'A3:G3',]
          excel.export_json_to_excel({
            header: Object.keys(headers),
            data: data1,
            filename,
            autoWidth: true,
            bookType: 'xlsx',
            multiHeader,
            merges
          })
          Message.success('导出成功')
        } else {
          Message.error('暂无数据')
        }
        exportDiglog.value = false
        loadingInstance.close()
      } catch (error) {
        exportDiglog.value = false
        loadingInstance.close()  //关闭loading
      }
    })
  } else {
    Message.warning('请选择时间')
  }
}
//导出的转换格式的方法
const formatJson = (headers, rows) => {
  return rows.map(item => {
    return Object.keys(headers).map(key => {
      if (headers[key] === 'workingTime') {
        return formatSeconds(item[headers[key]] / 1000)
      }
      return item[headers[key]]
    })
  })
}
//导出弹层关闭的回调
const closeExportDiglog = () => {
  exportDiglog.value = false
}

//定时弹层
let fixedDialog = ref(false)
//点击项的id
let deviceId = ref('')
//点击定时按钮
const openFixedTime = (data) => {
  deviceId.value = data
  fixedDialog.value = true
}
//关闭定时器
const closeFixedTime = () => {
  fixedDialog.value = false
}
</script>

<script>
export default {
  name: 'list2'
}
</script>

<style lang='scss' scoped>
.menu_box_item {
  height: 47px;
  line-height: 47px;
  text-align: center;
  font-size: 24px;
  font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
  font-weight: normal;
  color: #09aba0;
  border-radius: 4px;
  background: url("~@/assets/img/border1.png") no-repeat;
  background-size: 100% 100%;
  font-size: 14px;
  font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
  font-weight: normal;
  color: #08f4da;
  cursor: pointer;
}
.active {
  background-color: #025c63;
}
.table_contant {
  margin-top: 11px;
  height: 840px;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px 16px;
  ::v-deep .el-input__inner {
    background: url("~@/assets/img/border1.png") no-repeat;
    background-size: 100% 100%;
    border: none;
    color: rgba(8, 244, 218, 0.8);
    height: 47px;
  }
  .search_box {
    width: 84px;
    height: 43px;
    background: rgba(79, 193, 180, 0.3);
    font-size: 14px;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: #08f4da;
    text-align: center;
    line-height: 43px;
    cursor: pointer;
  }
}
.export_box {
  width: 126px;
  height: 25px;
  background: rgba(8, 244, 218, 0.28);
  border-radius: 4px;
  font-size: 12px;
  font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
  font-weight: normal;
  color: #ffffff;
  line-height: 25px;
  text-align: center;
  cursor: pointer;
}
.select_time {
  ::v-deep .el-dialog {
    padding-top: 50px;
    background: url("~@/assets/img/4.0/dialog.png") no-repeat !important;
    background-size: 100% 100% !important;
    .el-dialog__header {
      text-align: center;
      .el-dialog__title {
        font-size: 30px;
        font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
        font-weight: normal;
        color: #08f4da;
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #ffffff;
      }
    }
    .el-range-separator {
      z-index: 9;
    }
  }
}
.fixed_time {
  ::v-deep .el-dialog {
    background: url("~@/assets/img/4.0/dialog.png") no-repeat !important;
    background-size: 100% 100% !important;
    color: #fff !important;
    .el-dialog__title {
      font-size: 30px;
      font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
      font-weight: normal;
      color: #08f4da;
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #ffffff;
    }
    .el-radio {
      color: #fff;
      // margin-right: 100px;
    }
    .el-switch.is-checked .el-switch__core {
      border-color: #08f4da;
      background-color: #08f4da;
    }
  }

  //表格整体背景
  ::v-deep .el-table {
    color: rgba(115, 218, 201, 0.5);
    font-size: 12px;
    background-color: transparent;
  }
  // 表格内容背景
  ::v-deep .el-table tr {
    background-color: transparent;
  }
  //表头北京
  ::v-deep .el-table th {
    background-color: rgba(26, 26, 26, 0.3) !important;
  }
  // 鼠标经过时背景
  ::v-deep .el-table tbody tr:hover > td {
    background: none !important;
  }
  // 表格底部边框
  ::v-deep .el-table td {
    border-bottom: none;
  }
  //表格最下面底部边框
  ::v-deep .el-table::before {
    width: 0;
  }
  //每一行的高度
  ::v-deep .el-table__row {
    height: 40px;
    background-color: rgba(10, 137, 133, 0.1) !important;
  }
  //选择框的样式
  ::v-deep .el-checkbox__inner {
    background-color: transparent !important;
    border: 0.00521rem solid rgba(104, 104, 104, 1);
  }
  //选中的样式
  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    border-color: rgba(8, 244, 218, 1);
  }
}
//分页
::v-deep .el-pagination button:disabled {
  background: transparent;
  color: #fff;
}
::v-deep .number {
  background: transparent !important;
  color: #fff;
}
::v-deep .el-pagination .btn-next,
.el-pagination .btn-prev {
  background: transparent;
  color: #fff;
}
::v-deep .el-pagination .btn-prev {
  background: transparent;
  color: #fff;
}
</style>