<template>
  <div>
    <el-table :data="props.list" style="width: 100%" class="my_table" v-loading='props.loading'
      element-loading-background='rgba(122, 122, 122, 0.5)' ref="multipleTableRef"
      @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" />
      <el-table-column label="序号" align="center" type="index" width="90" />
      <el-table-column prop="deviceId" align="center" label="设备编号" />
      <el-table-column prop="name" align="center" label="设备名称">
        <template v-slot="{row}">
          <div class="flex col-center row-center">
            {{row.name}}
            <i class="el-icon-edit-outline  fs16 ml14" style=" color:'#4A8E8BFF" @click="changgeName(row)"></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="userName" align="center" label="绑定用户" />
      <el-table-column prop="userPhone" align="center" label="绑定手机号" />
      <el-table-column prop="createTime" align="center" label="绑定时间" :formatter="createTimeFilter" />
      <el-table-column prop="workingTime" align="center" label="使用时间" v-if="listType=='1'"
        :formatter="workingTimeFilter" />
      <el-table-column prop="status" align="center" label="当前状态">
        <template v-slot="{row}">
          <div :class="[row.status==1?'onlineStatus':'onlineStatus']">{{useType(row.status)}}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" v-if="props.listType=='0'">
        <template v-slot="{row}">
          <el-button link :type="row.status==2||row.status==3?'warning':'success'" size="small"
            @click="onOroff(row)">{{row.status==2||row.status==3?'关机':'开机'}}</el-button>
          <el-button link type="primary" size="small" @click="FixedTime(row.deviceId)">定时</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import { computed, defineEmit, defineProps, ref, watch } from 'vue';
import { switchDevice, getDeviceStatus } from '@/api/list'
import { formatSeconds } from '@/utils/pubilcjs'
import { formatDate } from '@/filters/index'
import Message from 'element-plus/lib/el-message';
import { ElMessage, ElMessageBox } from 'element-plus'
const props = defineProps(['list', 'loading', 'listType', 'selectDeviceId'])  // listType  0等离子  1水离子   selectDeviceId多选的数组
const $emit = defineEmit(['editName', 'updateList', 'openFixedTime', 'checkTable'])
const useType = (cellValue) => {
  let str = ''
  if (cellValue == 2 || cellValue == 3) { //2,3都是在线
    str = '在线'
  } else {
    str = '离线'
  }
  return str
}

const changgeName = (row) => {
  $emit('editName', row)
}
const onOroff = (row) => {
  let query = {
    deviceId: row.deviceId,
    state: null
  }
  let tip = ''
  if (row.status == 2 || row.status == 3) {
    tip = '关机'
    query.state = '0'
  } else {
    tip = '开机'
    query.state = '1'
  }
  ElMessageBox.confirm(`确定${tip}么?`).then((success) => {
    switchDevice(query).then(res => {
      Message.success('操作成功')
      getDeviceStatus({ ...query, userOperateId: res.data.userOperateId, status: query.state })
      $emit('updateList')
    })
  })

}
//打开定时
const FixedTime = (deviceId) => {
  $emit('openFixedTime', deviceId)
}
const workingTimeFilter = (row, column, cellValue, index) => {
  return formatSeconds(cellValue / 1000)
}
const createTimeFilter = (row, column, cellValue, index) => {
  return formatDate(cellValue * 1000)
}
// 多选
const handleSelectionChange = (arr) => {
  let arr1 = arr.map(item => {
    return item.deviceId
  })
  $emit('checkTable', arr1)
}
const multipleTableRef = ref(null)
//取消多选
const toggleSelection = (rows) => {
  if (rows) {
    rows.forEach((row) => {
      multipleTableRef.value.toggleRowSelection(row, undefined)
    })
  } else {
    multipleTableRef.value.clearSelection()
  }
}
//监听如果父组件导出全部数据
watch(() => props.selectDeviceId, (newValue) => {
  // console.log(newValue);
  if (newValue.length < 1) {
    toggleSelection()
  }
})
</script>

<style lang="scss" scoped>
//在线
.onlineStatus {
  color: #08f4daff !important;
}
.onlineStatus {
  color: #f49708ff !important;
}
//表格整体背景
::v-deep .el-table {
  color: rgba(115, 218, 201, 0.5);
  font-size: 12px;
  background-color: transparent;
}
// 表格内容背景
::v-deep .el-table tr {
  background-color: transparent;
}
//表头北京
::v-deep .el-table th {
  background-color: #1a1a1a !important;
}
// 鼠标经过时背景
::v-deep .el-table tbody tr:hover > td {
  background: none !important;
}
// 表格底部边框
::v-deep .el-table td {
  border-bottom: none;
}
//表格最下面底部边框
::v-deep .el-table::before {
  width: 0;
}
//每一行的高度
::v-deep .el-table__row {
  height: 40px;
  background-color: rgba(10, 137, 133, 0.1) !important;
}
//选择框的样式
::v-deep .el-checkbox__inner {
  background-color: transparent !important;
  border: 0.00521rem solid rgba(104, 104, 104, 1);
}
//选中的样式
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  border-color: rgba(8, 244, 218, 1);
}
</style>