<template>
  <el-dialog v-model="props.fixedDialog" width="40%" :title="isCreate" @close="cancel" center
    :close-on-click-modal='false'>
    <template v-if="showList">
      <div class="creat_box" @click="showList=false">新建定时器</div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column label="开机时间">
          <template v-slot='{row}'>
            <div>{{row.switchOnH}}:{{row.switchOnM}}</div>
          </template>
        </el-table-column>
        <el-table-column label="关机时间">
          <template v-slot='{row}'>
            <div>{{row.switchOffH}}:{{row.switchOffM}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="次数">
          <template v-slot='{row}'>
            <div> {{row.cycle==0? '每天':'仅一次'}}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="500">
          <template v-slot="{row}">
            <div class="flex row-center col-center">
              <div class="mr20">
                <el-button type="primary" @click="edit(row)">修改</el-button>
                <el-button type="danger" @click="detele(row)">删除</el-button>
                <el-button :type="row.status==1?'warning':'success'"
                  @click="changeType(row)">{{row.status==1?'关闭':'启用'}}</el-button>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <template v-else>
      <div class="dingshi_tab">
        <el-radio-group v-model="query.cycle">
          <el-radio label="0" size="large">每日</el-radio>
          <el-radio label="1" size="large">仅一次</el-radio>
        </el-radio-group>
      </div>

      <div class="flex row-center mt20">
        <div class="mr20">
          <el-time-select v-model="openTime" start="00:00" step="00:15" end="24:00" placeholder="开机时间" />
        </div>
        <el-time-select v-model="closeTime" :start="closeStaetTime" step="00:15" end="24:00" placeholder="关机时间" />
      </div>
    </template>
    <template #footer v-if="!showList">
      <span class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="confirm">
          确认
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { computed, defineEmit, defineProps, nextTick, reactive, ref, watch } from 'vue';
import { chooseInterval, getTimerTask, stopTimerTask, startInterval, delTimerTask, changeTimerTask } from '@/api/list'
import Message from 'element-plus/lib/el-message';
const props = defineProps(['fixedDialog', 'deviceId'])
let query = reactive({
  cycle: '0',  //周期：0每天，1一次
  onHour: '',//开机h
  onMinute: '',//开机m
  offHour: '',//关机h
  offMinute: '',//	关机m
  deviceId: '',
  switchId: null
})
let showList = ref(true)
const isCreate = computed(() => {
  return showList.value ? '定时设置' : '新建定时器'
})
let openTime = ref('')  //开启时间
let closeTime = ref('')  //关闭时间
//计算属性  计算结束时间的值
const closeStaetTime = computed(() => {
  if (openTime.value) {
    let arr1 = openTime.value.split(':')[0]
    let arr2 = openTime.value.split(':')[1]
    let time = ''
    if (parseInt(arr1) >= 22) {
      time = '23'
      arr2 = '59'
    } else {
      time = (parseInt(arr1) + 2).toString()
    }
    time = time.length == 1 ? "0" + time : time
    return `${time}:${arr2}`
  } else {
    return '00:00'
  }
})
//监听器
watch(openTime, (newValue) => {
  query.onHour = newValue.split(':')[0]
  query.onMinute = newValue.split(':')[1]
})
watch(closeTime, (newValue) => {
  query.offHour = newValue.split(':')[0]
  query.offMinute = newValue.split(':')[1]
})
watch(() => props.deviceId, (newValue) => {
  console.log(333, newValue);
  query.deviceId = newValue
  getList(newValue)
  // query.offHour = newValue.split(':')[0]
  // query.offMinute = newValue.split(':')[1]
})
const tableData = ref([])
//获取定时器列表
const getList = (deviceId) => {
  getTimerTask({
    deviceId,
    pageNum: 1,
    pageSize: 10
  }).then(res => {
    tableData.value = res.data
  })
}
//点击确认
const confirm = async () => {
  if (query.switchId) {
    await changeTimerTask(query)
  } else {
    const res = await chooseInterval(query)
    //新建成功后启用定时器
    await startInterval({ switchId: res.data })
  }
  Message.success('操作成功')
  getList(props.deviceId)
  showList.value = true
}
const $emit = defineEmit(['closeFixedTime'])
//点击取消
const cancel = () => {
  query = reactive({
    cycle: '0',  //周期：0每天，1一次
    onHour: '',//开机h
    onMinute: '',//开机m
    offHour: '',//关机h
    offMinute: '',//	关机m
    deviceId: '',
    switchId: null
  })
  $emit('closeFixedTime')
  showList.value = true
}
//定时器开关
const changeType = (row) => {
  let api = null
  if (row.status == '0') {
    api = startInterval({ switchId: row.id })
  } else {
    api = stopTimerTask({ switchId: row.id })
  }
  api.then(res => {
    Message.success('操作成功')
    getList(props.deviceId)
  })
}
//删除
const detele = (row) => {
  if (row.status == 0) {
    delTimerTask({ switchId: row.id }).then(res => {
      Message.success('删除成功')
      getList(props.deviceId)
    })
  } else {
    Message.warning('先停用才能删除')
  }
}
//修改
const edit = (row) => {
  openTime.value = `${row.switchOnH}:${row.switchOnM}`
  closeTime.value = `${row.switchOffH}:${row.switchOffM}`
  query.cycle = row.cycle + ''
  query.switchId = row.id
  showList.value = false
}
</script>

<script>
export default {
  name: 'fixedTime',
}
</script>

<style lang="scss" scoped>
.creat_box {
  margin-bottom: 10px;
  width: 120px;
  height: 40px;
  background: rgba(8, 244, 218, 0.6);
  color: #fff;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
.dingshi_tab {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 80%;
  height: 50px;
  background: rgba(8, 244, 218, 0.2);
  border-radius: 10px;
}
</style>