<template>
  <el-dialog title="请选择您想导出的数据的时间" v-model="exportDiglog" width="40%" :close-on-click-modal='false' @close='cancel'>
    <div class="time_box">
      <el-date-picker v-model="time" type="daterange" range-separator="至" start-placeholder="开始日期"
        end-placeholder="结束日期" format="YYYY/MM/DD" value-format="yyyy-MM-dd" :disabledDate="isFutureDate">
      </el-date-picker>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </div>
  </el-dialog>

</template>

<script setup>
import { defineEmit, defineProps, ref, reactive, } from 'vue';
defineProps(['exportDiglog'])
let time = ref('')
const $emit = defineEmit(['confirmExport', 'closeExportDiglog'])
const confirm = () => {
  $emit('confirmExport', time.value)
}
const cancel = () => {
  time.value = ''
  $emit('closeExportDiglog')
}
const isFutureDate = (date) => {
  const today = new Date();
  return date > today;
}
</script>
<script >
export default {
  name: 'selectTime'
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  justify-content: center;
  margin-top: 65px;
}
.time_box {
  display: flex;
  justify-content: center;
}
</style>