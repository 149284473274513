<template>
  <div>
    <el-table :data="list" style="width: 100%" class="my_table" v-loading='loading'
      element-loading-background='rgba(122, 122, 122, 0.5)'>
      <!-- <el-table-column type="selection" width="55" /> -->
      <el-table-column label="序号" align="center" type="index" width="90" />
      <el-table-column prop="name" align="center" label="设备名称">
        <template v-slot="{row}">
          <div class="flex col-center row-center">
            {{row.deviceName}}
            <i class="el-icon-edit-outline  fs16 ml14" style=" color:'#4A8E8BFF" @click="changgeName(row)"></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="deviceId" align="center" label="设备编号" />
      <el-table-column prop="createTime" align="center" label="绑定时间" :formatter="createTimeFilter" />
    </el-table>
  </div>
</template>

<script setup>
import { computed, defineEmit, defineProps, ref } from 'vue';
import { switchDevice } from '@/api/list'
import { formatSeconds } from '@/utils/pubilcjs'
import { formatDate } from '@/filters/index'
defineProps(['list', 'loading', 'listType'])  // listType  0等离子  1水离子
const $emit = defineEmit(['editName', 'updateList', 'openFixedTime'])
const changgeName = (row) => {
  $emit('editName', row)
}
const createTimeFilter = (row, column, cellValue, index) => {
  return formatDate(cellValue * 1000)
}

</script>

<style lang="scss" scoped>
//在线
.onlineStatus {
  color: #08f4daff !important;
}
.onlineStatus {
  color: #f49708ff !important;
}
//表格整体背景
::v-deep .el-table {
  color: rgba(115, 218, 201, 0.5);
  font-size: 12px;
  background-color: transparent;
}
// 表格内容背景
::v-deep .el-table tr {
  background-color: transparent;
}
//表头北京
::v-deep .el-table th {
  background-color: #1a1a1a !important;
}
// 鼠标经过时背景
::v-deep .el-table tbody tr:hover > td {
  background: none !important;
}
// 表格底部边框
::v-deep .el-table td {
  border-bottom: none;
}
//表格最下面底部边框
::v-deep .el-table::before {
  width: 0;
}
//每一行的高度
::v-deep .el-table__row {
  height: 40px;
  background-color: rgba(10, 137, 133, 0.1) !important;
}
//选择框的样式
::v-deep .el-checkbox__inner {
  background-color: transparent !important;
  border: 0.00521rem solid rgba(104, 104, 104, 1);
}
//选中的样式
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  border-color: rgba(8, 244, 218, 1);
}
</style>